.page-header {
    /* position: fixed; */
    width: 100%;
    box-shadow: 0 2px 8px #f0f1f2;
    z-index: 10;
    padding: 10px;
    background-color: rgb(255, 255, 255);
  }
  .gutter-row {
    padding: 8px 0;
    background: #ffffff;
  }
