.carousel-style {
    height: 160px;
    color: #fff;
    line-height: 160px;
    text-align: center;
    background: #8e8f91;
  };

  .ant-tabs-tab {
    background-color: #363636 !important;
  }
  
  .ant-tabs-tab-active {
    color: #a04444 !important;
  }

  