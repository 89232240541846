.app-content {
  /* margin-top: 15px;    */
}

.app-container {
  height: 44px;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 0px;
  /* background: rgba(199, 25, 25, 0.3); */
}

.site-layout .site-layout-background {
    background: #fff;
}
.ant-layout-sider-trigger-or {
  /* background-color: rgb(211, 230, 199); */
}

.site-page-header .ant-page-header .ant-page-header-ghost{
  background-color: #dde7c7 !important;
}

thead[class*="ant-table-thead"] th{
  background-color: #f1f7ee !important;
  border-bottom-width: 1px !important;
  border-bottom-color: rgb(194, 199, 190) !important;
}

.material-icons {
  display:inline-flex;
  vertical-align:top;
}

