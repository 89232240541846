@import '~antd/dist/antd.css';
body {
  margin: 0;
  padding: 0;
  font-family: quicksand;
}

.container {
  /* max-width: 1000px; */
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  /* padding-right: 15px; */
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.7em;
  }
}

a {
  /* color: #1890ff; */
text-decoration: none;
background-color: transparent;
outline: none;
cursor: pointer;
transition: color 0.3s;
-webkit-text-decoration-skip: objects;
}