.completed {
    background: #dfe7fd;
}

.cancelled {
    background: #f9dcc4;
}

.uptodate {
    background: #d0f4de;
}

.rowClassName1 {
    /* border-radius: 10px; */
    
    border-color: rgb(160, 59, 118);
    border-left-width: 10px;
    border-left-color: #2958b6;
    
    border-style: solid;
  }
  
  .rowClassName1 td:first-child {
    background-color: #2958b6;
    border-left-width: 3px;
    border-left-color: #8d4b15;
     }
  
  /* .rowClassName1 td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  } */

  thead[class*="ant-table-thead"] th{
    font-weight: 800;
  }

  .ant-tabs-ink-bar {
    height: 5px;
    border-right: 5px solid #FF5230;
    font-weight: 800;
    background-color: #2958b6 !important;
  }


  /* .site-page-header .ant-page-header-heading-title {
      background-color: #929292;
      content: '';
      display: block;
      color: rgb(255, 255, 255);
      padding-left: 5px;
      padding-right: 5px;

      /* height: 3px;
      width: 75px;
      margin-bottom: 5px; */
  /* } */
  /* .site-page-header .ant-page-header-heading-title { */
      /* background-color: #c50000;
      content: '';
      display: block;
      position:absolute; 
      /* right:0;  */
      /* bottom:0;
      height: 3px;
      width: 75px; */ 
      /* margin-bottom: 0.25em; */
  /* } */
  