.table-row-pt {
    background-color: #F4F4F2;
    font-weight: 600;
    border-bottom: 1px;
    border-bottom-color: blue;
}
.table-row-dark {
    background-color: #E8E8E8;
    font-weight: 700;
}
.finance-table .ant-table table { font-size: 11px; }